import { Icon, IconButton } from "@chakra-ui/react"
import TextField, { TextFieldProps } from "app/common/components/TextField"
import { forwardRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoEye, IoEyeOff } from "react-icons/io5"

export interface PasswordFieldProps extends TextFieldProps {}

const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
  ({ type, ...props }, ref) => {
    const { t } = useTranslation()
    const [isVisible, setVisibility] = useState(false)
    return (
      <TextField
        ref={ref}
        type={isVisible ? "text" : "password"}
        // apparently, many browsers ignore autocomplete=off for password fields
        // https://stackoverflow.com/questions/17719174/autocomplete-off-is-not-working-when-the-input-type-is-password-and-make-the
        autoComplete="new-password"
        inputRightElement={
          <IconButton
            isRound
            size="sm"
            variant="ghost"
            colorScheme="expertBlue"
            aria-label={isVisible ? t("actions.hidePassword") : t("actions.showPassword")}
            onClick={() => setVisibility((visible) => !visible)}
          >
            <Icon as={isVisible ? IoEyeOff : IoEye} height={5} width={5} />
          </IconButton>
        }
        {...props}
      />
    )
  }
)

export default PasswordField
