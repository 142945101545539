import { Link as ChakraLink, LinkProps } from "@chakra-ui/react"
import { Link as BlitzLink, LinkProps as BlitzLinkProps } from "blitz"
import { FC } from "react"

interface Props extends Omit<LinkProps, "href"> {
  href: BlitzLinkProps["href"]
  passHref?: BlitzLinkProps["passHref"]
}

const Link: FC<Props> = ({ href, passHref = true, children, ...restProps }) => {
  return (
    <BlitzLink href={href} passHref={passHref}>
      <ChakraLink {...restProps}>{children}</ChakraLink>
    </BlitzLink>
  )
}

export default Link
