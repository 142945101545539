import { SimpleGrid } from "@chakra-ui/layout"
import { Center, Flex, GridItem, HStack, Image, Link, Text } from "@chakra-ui/react"
import { EnvironmentIndicator } from "app/common/components/EnvironmentIndicator"
import Head from "app/core/components/Head"
import { Routes, useRouter } from "blitz"
import { ReactNode, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { CONTENT_WIDTH_PX } from "../../common/config/constants"
import { useAuthContext } from "../../utils/misc/AuthContext"

type LayoutProps = {
  title?: string
  children: ReactNode
  withCornerBackground?: boolean
}

const AuthLayout = ({ title, children, withCornerBackground = false }: LayoutProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { currentUser } = useAuthContext()

  useEffect(() => {
    if (currentUser) {
      void router.push("/")
    }
  }, [router, currentUser])

  return (
    <Center
      minHeight="100vh"
      background="brand.expertBlue"
      color="white"
      position="relative"
      overflow="auto"
      alignItems="flex-start"
    >
      {withCornerBackground ? (
        <Image
          src="images/corner_background_swipes.svg"
          position={"absolute"}
          display={["none", "none", "none", "none", "block"]}
          top={0}
          right={0}
          zIndex={-1}
          alt=""
        />
      ) : null}
      <Head title={title} pageTitleLast description={t("misc.siteDescription")} />

      <SimpleGrid columns={2} w={CONTENT_WIDTH_PX} marginTop="150px">
        <GridItem colSpan={2} mb={10}>
          <Flex>
            <Flex
              transform={"translateX(-18px)"}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              pos="relative"
              ml={5}
            >
              <Image
                src="images/arcensus-logo_monochrome-white.svg"
                alt="arcensus"
                width={300}
                cursor="pointer"
                onClick={() => router.push(Routes.LoginPage())}
              />

              <EnvironmentIndicator bottom={-6} />
            </Flex>
            {/* <Flex alignItems="center" ml={20}>
              <Link
                href="https://www.arcensus-diagnostics.com/"
                isExternal
                target="_csv"
                color="white"
              >
                <Text fontSize="2xl" fontWeight="bold">
                  <ArrowBackIcon color="white" me={2} />
                  Arcensus Home Page
                </Text>
              </Link>
            </Flex> */}
          </Flex>
        </GridItem>

        {children}
      </SimpleGrid>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        mt={5}
        position="absolute"
        bottom="20px"
        fontSize="14px"
      >
        <Text margin="0 52px 0 0">
          {t("messages.bodies.footer", { YYYY: new Date().getFullYear() })}
        </Text>
        <HStack spacing="16px" fontWeight="bold">
          <Link href="https://arcensus-diagnostics.com/toc" isExternal>
            {t("misc.footer.termsAndConditions")}
          </Link>
          <Link href="https://arcensus-diagnostics.com/privacy-policy" isExternal>
            {t("misc.footer.privacyPolicy")}
          </Link>
          <Link href="https://arcensus-diagnostics.com/consent" isExternal>
            {t("misc.footer.consent")}
          </Link>
        </HStack>
        {/*<LocaleSelect />*/}
      </Flex>
    </Center>
  )
}

export default AuthLayout
