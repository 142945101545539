import { TFunction } from "react-i18next"
import * as z from "zod"

import { optionalSafeString, safeEmail, safeString } from "../security/validation/templates"

export const RegistrationCodeSchema = (t: TFunction = () => {}) =>
  z.object({
    registrationCode: z.string().regex(/\d\d\d\d\d\d\d\d$/, t("errors.registrationCodeRegex")),
  })

const getPasswordSchema = (t: TFunction) => {
  return z
    .string()
    .min(8)
    .max(100)
    .regex(/^(?=.*[A-Za-z])(?=.*[0-9]).*$/, t("errors.password_regex"))
}

export const getSignupSchema = (t: TFunction = () => {}) =>
  z.object({
    firstName: safeString,
    lastName: safeString,
    email: safeEmail,
    password: getPasswordSchema(t),
    phone: optionalSafeString,
    registrationCode: optionalSafeString,
    title: optionalSafeString,
  })

export const SignupWithPreferredLocale = getSignupSchema().extend({
  preferredLocale: z.string(),
})

export const getDoctorSignupSchemaPage1 = (t: TFunction = () => {}) =>
  z.object({
    title: safeString,
    firstName: safeString,
    lastName: safeString,
    email: safeString.and(z.string().email()),
    password: getPasswordSchema(t),
    phone: safeString,
  })

export const getDoctorSignupSchemaFull = (t: TFunction = () => {}) =>
  getDoctorSignupSchemaPage1(t).extend({
    institute: safeString,
    department: safeString,
    addressLine1: safeString,
    addressHouseNumber: optionalSafeString,
    addressLine2: optionalSafeString,
    addressCity: safeString,
    addressPostCode: safeString,
    addressCountry: safeString,
  })

export const DoctorSignupWithPreferredLocale = getDoctorSignupSchemaFull().extend({
  preferredLocale: z.string(),
})

export const getDistributorSignupSchemaPage1 = (t: TFunction = () => {}) =>
  z.object({
    firstName: safeString,
    lastName: safeString,
    email: safeString.and(z.string().email()),
    phone: safeString,
    password: getPasswordSchema(t),
  })

export const getDistributorSignupSchemaFull = (t: TFunction = () => {}) =>
  getDistributorSignupSchemaPage1(t).extend({
    officeUnit: safeString,
    addressLine1: safeString,
    addressHouseNumber: optionalSafeString,
    addressLine2: optionalSafeString,
    addressCity: safeString,
    addressPostCode: safeString,
    addressCountry: safeString,
  })

export const DistributorSignupWithPreferredLocale = getDistributorSignupSchemaFull().extend({
  preferredLocale: z.string(),
})

export const Login = z.object({
  email: safeEmail,
  password: z.string(),
  twoFactorToken: z.string(),
})

export const ForgotPassword = z.object({
  email: safeEmail,
})

export const getResetPasswordWithoutTokenSchema = (t: TFunction = () => {}) => {
  return z.object({
    password: getPasswordSchema(t),
  })
}

export const ResetPasswordWithoutToken = getResetPasswordWithoutTokenSchema()

export const ResetPassword = ResetPasswordWithoutToken.and(
  z.object({
    token: z.string(),
  })
)

export const ResendVerification = z.object({
  email: safeEmail,
})

export const VerifyEmail = z.object({
  token: z.string(),
})

export const AcceptInvite = (t: TFunction = () => {}) =>
  VerifyEmail.extend({
    firstName: safeString,
    lastName: safeString,
    password: getPasswordSchema(t),
    phone: optionalSafeString,
  })

export const VerifyEmailChange = z.object({
  token: z.string().regex(/^\d{6}$/, { message: "Token must be exactly 6 digits." }),
  email: safeEmail,
})
