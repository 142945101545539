import { Button } from "@chakra-ui/button"
import { Text } from "@chakra-ui/layout"
import { Box, Flex } from "@chakra-ui/react"
import { LoginForm } from "app/auth/components/LoginForm"
import { BlitzPage, Link as BlitzLink, Routes, useRouter } from "blitz"
import { Translation, useTranslation } from "react-i18next"

import AuthLayout from "../layouts/AuthLayout"

const LoginPage: BlitzPage = () => {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <>
      <LoginForm
        onSuccess={() => {
          void router.push("/?login=true")
        }}
      />

      <Flex flexDirection="column" justifyContent="flex-end" ms={16} data-cy="wrapper-login-page">
        <Text fontSize="4xl" fontWeight="bold">
          {t("labels.newToArcensus")}
        </Text>

        <Box>
          <BlitzLink href={Routes.SignupPage()}>
            <Button colorScheme="white" variant="outline" data-cy="sign-up-button" mt={4}>
              {t("actions.goToSignup")}
            </Button>
          </BlitzLink>
        </Box>
      </Flex>
    </>
  )
}

LoginPage.redirectAuthenticatedTo = "/?login=true"
LoginPage.getLayout = (page) => (
  <Translation>
    {(t) => (
      <AuthLayout title={t("pageTitles.login")} withCornerBackground={true}>
        {page}
      </AuthLayout>
    )}
  </Translation>
)

export default LoginPage
