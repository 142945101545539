import { Button } from "@chakra-ui/button"
import { Flex } from "@chakra-ui/layout"
import { Box, Text } from "@chakra-ui/react"
import login from "app/auth/mutations/login"
import { Login } from "app/auth/validations"
import { Form, FORM_ERROR } from "app/common/components/Form"
import Link from "app/common/components/Link"
import PasswordField from "app/common/components/PasswordField"
import TextField from "app/common/components/TextField"
import {
  EmailVerificationError,
  LockoutError,
  TwoFactorInitiationError,
  UserRestricted,
  WrongTwoFactorTokenError,
} from "app/utils/errorHandling/backendErrors"
import { AuthenticationError, Routes, useMutation } from "blitz"
import { useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import { useAuthContext } from "../../utils/misc/AuthContext"

type LoginFormProps = {
  onSuccess: () => void
}

export const LoginForm = (props: LoginFormProps) => {
  const { t } = useTranslation()
  const [loginMutation, { isLoading }] = useMutation(login)
  const { refetchUser } = useAuthContext()

  const [secondFactor, setSecondFactor] = useState(false)

  return (
    <>
      <Form
        schema={Login}
        initialValues={{ email: "", password: "", twoFactorToken: "" }}
        onSubmit={async (values) => {
          try {
            await loginMutation(values)
            await refetchUser()
            props.onSuccess()
          } catch (error) {
            if (error instanceof TwoFactorInitiationError) {
              setSecondFactor(true)
            } else if (error instanceof WrongTwoFactorTokenError) {
              return { [FORM_ERROR]: t("labels.wrongTwoFactorToken") }
            } else if (error instanceof AuthenticationError) {
              return { [FORM_ERROR]: t("messages.bodies.loginAuthError") }
            } else if (error instanceof LockoutError) {
              return { [FORM_ERROR]: t("messages.bodies.lockoutError") }
            } else if (error instanceof UserRestricted) {
              return { [FORM_ERROR]: t("messages.bodies.userIsRestricted") }
            } else if (error instanceof EmailVerificationError) {
              return {
                [FORM_ERROR]: (
                  <Text data-cy="error-email-verification">
                    <Trans
                      t={t}
                      i18nKey="errors.loginEmailVerificationError"
                      components={{
                        a: (
                          <Link
                            href={Routes.ResendVerificationPage()}
                            textDecoration="underline"
                            fontWeight="bold"
                          />
                        ),
                      }}
                    />
                  </Text>
                ),
              }
            } else {
              return {
                [FORM_ERROR]: t("messages.titles.unexpectedError", { error: error.toString() }),
              }
            }
          }
        }}
      >
        {secondFactor ? (
          <>
            <Text>{t("labels.twoFactorTokenLoginInfo")}</Text>
            <TextField name="twoFactorToken" label={t("labels.twoFactorToken")} variant="filled" />
          </>
        ) : (
          <>
            <TextField
              name="email"
              label={t("labels.email")}
              placeholder={t("placeholders.authEmail")}
              variant="filled"
            />
            <PasswordField
              name="password"
              label={t("labels.password")}
              placeholder={t("placeholders.password")}
              variant="filled"
            />
            
            <Box mt={5}>
              <Link href={Routes.ForgotPasswordPage()}>{t("actions.goToForgotPassword")}</Link>
            </Box>
          </>
        )}

        <Flex mt={5}>
          <Button
            type="submit"
            colorScheme="lightBlue"
            color="brand.expertBlue"
            isLoading={isLoading}
            data-cy="login-button"
          >
            {t("actions.login")}
          </Button>
        </Flex>
      </Form>
    </>
  )
}

export default LoginForm
